import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar == 0; }

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });

    $('#bt-search').on('click', function(){
        $('#search').toggleClass('active');
    });

    $('.search--close').on('click', function(){
        $('#search').removeClass('active');
    });

    // INIT ALL FUNCTIONS
    imgBack();
    if( $('.bloc--carrousel').length != 0 ||
    $('.bloc--slider').length != 0 ||
    $('.intro--galerie').length != 0 ||
    $('.bloc--slider-brands').length != 0 ||
    $('.wp-block-products-by-attribute').length != 0 ) { sliders(); }
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
    //menuImage();

    // ACTIONS WINDOW
    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });
 
    resize()
    $(window).on('resize', function(){
        resize();
    });

    $('ul.products').find('li.product-category:last').after('<li class="product-separation"></li>');
});

function resize(){
    $('main').css( 'padding-top', $('#masthead').innerHeight() );

    if($('body.admin-bar').length != 0){
        $('#masthead').css('top', $('#wpadminbar').innerHeight());
        if($(window).width() < 587 && $(window).scrollTop() != 0 ){
            $('body.admin-bar #btNav').css('top', ($('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }else{
            $('body.admin-bar #btNav').css('top', ($('#wpadminbar').innerHeight() + $('#masthead').innerHeight()*0.5 - $('#btNav').innerHeight()*0.5) );
        }
    }
}

function actionsScroll(){
    $('#search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });

    // $('.wc-block-grid .wc-block-grid__product .wc-block-grid__product-link .wc-block-grid__product-image').each(function(){
    //     var urlImgCurrent = $(this).children('img').attr('src');
    //     if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
    //         $(this).addClass('ratio--carre');
    //         $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: contain; background-repeat: no-repeat;');
    //         $(this).find('img').remove();		
    //     }	
    // });
}

function sliders(){
    $('.bloc--carrousel').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-arrow slide-prev material-symbols-outlined">arrow_back_ios</span>',
        nextArrow: '<span class="slide-arrow slide-next material-symbols-outlined">arrow_forward_ios</span>',
    });

    $('.intro--galerie').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
        nextArrow: '<span class="slide-next"><i class="fa-solid fa-angle-right"></i></span>',
    });

    $('#galerie_intro').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: false,
        infinite: true,
        centerPadding: '18px',
    });

    $('.bloc--slider-brands').not('.slick-initialized').slick({
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 2500,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-arrow slide-prev material-symbols-outlined">arrow_back_ios</span>',
        nextArrow: '<span class="slide-arrow slide-next material-symbols-outlined">arrow_forward_ios</span>',
        responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    });

    $('.bloc--listing-brands ul.products, .wp-block-products-by-attribute ul.wc-block-grid__products').not('.slick-initialized').slick({
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 2500,
        respondTo: 'slider',
        responsive: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        prevArrow: '<span class="slide-arrow slide-prev material-symbols-outlined">arrow_back_ios</span>',
        nextArrow: '<span class="slide-arrow slide-next material-symbols-outlined">arrow_forward_ios</span>',
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 3
              }
            },
            {
                breakpoint: 700,
                settings: {
                  slidesToShow: 2
                }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    });

}

function onglets(){
    var hauteurMax = 0;
    $('.tab-content').each(function(){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
            $('.tab-content').css('min-height', hauteurMax);
        }
    });

    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            return false;
        }
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top bottom",
                    end: "top 85%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function menuImage(){
    $('#nav-main > li.menu-item').each(function(){   
        $(this).find('> ul.sub-menu').css('width', $('#nav-main').innerWidth() ).css('left', - $(this).offset().left + $('#nav-main > li.menu-item:eq(0)').offset().left);
    });
}